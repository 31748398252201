import styled from 'styled-components';

export const ItemPricesContainer = styled.section`
  width: 100%;
  max-width: 1120px;
  margin: 2rem 0 auto; /* topo | direita | inferior | esquerda */
  padding: 0;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
`;

export const ItemPricesCard = styled.div`
  background: ${props => props.theme['gray-100']};
  border-radius: 20px;
  padding: 2rem;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.theme['gray-300']};

    span:nth-of-type(2) {
      color: ${props => props.theme['red-500']};
    }
  }

  strong {
    color: ${props => props.theme['gray-500']};
    display: block;
    margin-top: 1rem;
    font-size: 1.5rem;
  }
`;

export const ItemPriceObservation = styled.div`
  width: 100%;
  max-width: 1120px;
  background: rgba(248, 248, 248, 0.8);
  border-left: 3px solid ${props => props.theme['red-500']};
  border-radius: 0px 20px 20px 0px;
  margin: 2rem 0 auto; /* topo | direita | inferior | esquerda */
  padding: 1.5rem;

  span {
    margin-top: 1rem;
    font-size: 1.25rem;
    color: ${props => props.theme['gray-500']};
  }
`;
