import styled from 'styled-components';

export const HeaderContent = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.25rem;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #a8a8b3;
    transition: color 0.2s;
    &:hover {
      color: #666;
    }
    svg {
      margin-right: 4px;
    }
  }

  button {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #a8a8b3;
    transition: color 0.2s;
    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    &:hover {
      color: #666;
    }
    svg {
      margin-right: 4px;
    }
  }
`;

export const WarehouseInfo = styled.section`
  margin-top: 4rem;

  header {
    display: flex;
    align-items: center;

    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }

    div {
      margin-left: 2rem;

      strong {
        font-size: 36px;
        color: #3d3d4d;
      }

      p {
        font-size: 18px;
        color: #737380;
        margin-top: 4px;
      }
    }
  }

  ul {
    display: flex;
    list-style: none;
    margin-top: 2rem;
    justify-content: space-between;
    align-items: baseline;

    li {
      & + li {
        margin-left: 80px;
      }

      strong {
        display: block;
        font-size: 24px;
        color: #3d3d4d;
      }

      span {
        display: block;
        margin-top: 4px;
        color: #6c6c80;
      }
    }
  }
`;

export const Warehouses = styled.div`
  margin-top: 2rem;

  a {
    background: #fff;
    border-radius: ${props => props.theme['--border-radius']};
    width: 100%;
    padding: 24px;
    display: block;
    text-decoration: none;

    display: flex;
    align-items: center;
    transition: transform 0.2s;

    &:hover {
      transform: translateX(10px);
    }

    & + a {
      margin-top: 16px;
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-content: space-between;
      margin: 0 16px;
      flex: 1;

      strong {
        font-size: 24px;
        color: #3d3d4d;
      }

      p {
        font-size: 18px;
        color: #a8a8b3;
        margin-top: 4px;
      }
    }

    div.quantity {
      display: flex;
      flex-direction: column;
      justify-content: right;
      margin: 0 40px 0 0;
      overflow: hidden;
      text-align: right;
      strong {
        font-size: 32px;
        color: #3d3d4d;
      }

      p {
        font-size: 18px;
        color: #a8a8b3;
        margin-top: 4px;
      }

    svg {
      margin-left: auto;
      color: #cbcbd6;
    }
  }
`;
