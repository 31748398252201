/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import React, { useState, useCallback, FormEvent, useEffect } from 'react';
import _ from 'lodash';
import { ComponentSelector } from 'components/ComponentSelector';
import { Title, Form, Errors } from './styles';
import Header from '../../components/Header';

const Dashboard: React.FC = () => {
  const [query, setQuery] = useState(() => {
    const storageQuery = localStorage.getItem(
      '@warehouse_explorer:lastQueryResults',
    );
    if (!storageQuery) {
      return '';
    }
    return JSON.parse(storageQuery);
  });
  const [inputError, setInputError] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState(() => {
    const storageQuery = localStorage.getItem(
      '@warehouse_explorer:lastQueryResults',
    );
    if (!storageQuery) {
      return 'nonExistentItem';
    }
    return JSON.parse(storageQuery);
  });
  const debounceQuery = useCallback(_.debounce(setDebouncedQuery, 500), []);

  useEffect(() => {
    localStorage.setItem(
      '@warehouse_explorer:lastQueryResults',
      JSON.stringify(debouncedQuery),
    );
  }, [debouncedQuery]);

  async function handleSearchItem(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();
    setInputError('');
    const invalidSearchTerm = /^\s{1,}/.test(query);
    if (!query || invalidSearchTerm) {
      setInputError('Digite um termo de busca válido');
      return;
    }
    try {
      debounceQuery(query);
    } catch (err) {
      setInputError('Erro na busca por esse item');
    }
  }

  return (
    <>
      <Header />
      <Title>Verifique o item em nosso estoque.</Title>
      <Form hasError={!!inputError} onSubmit={handleSearchItem}>
        <input
          value={query}
          onChange={event => {
            setQuery(event.target.value);
          }}
          placeholder="Digite seu termo de busca"
        />
        <button type="submit">Pesquisar</button>
      </Form>
      {inputError && <Errors>{inputError}</Errors>}
      <ComponentSelector inputString={debouncedQuery} />
    </>
  );
};
export default Dashboard;
