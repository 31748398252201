/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import api from 'services/apiClient';
import encodeRFC5987ValueChars from 'utils/encodeRFC5987ValueChars';
import WareHouseItem from 'dtos/IWareHouseItemDTO';
import WarehouseItemShelf from 'dtos/IWarehouseItemShelfDTO';
import WarehouseParams from 'dtos/IWarehouseParamsDTO';
import Header from 'components/Header';
import image_not_available from '../../assets/image_not_available.png';
import { HeaderContent, WarehouseInfo, Warehouses } from './styles';

const ItemShelfs: React.FC = () => {
  const { params } = useRouteMatch<WarehouseParams>();
  const [itemShelf, setItemShelf] = useState<WarehouseItemShelf | null>(null);
  const [itemShelfsArray, setItemShelfsArray] = useState<
    WarehouseItemShelf[] | null
  >(null);
  const [item, setItem] = useState<WareHouseItem | null>(null);
  const history = useHistory<History>();

  useEffect(() => {
    api
      .get(
        `warehouse/shelf/${encodeRFC5987ValueChars(
          params.itemCode,
        )}?warehouse=${params.location}`,
      )
      .then(response => {
        setItemShelf(response.data[0]);
        setItemShelfsArray(response.data);
      })
      .catch(Error);
  }, [params.itemCode, params.location]);

  useEffect(() => {
    api
      .get(`warehouse/${encodeRFC5987ValueChars(params.itemCode)}`)
      .then(response => {
        setItem(response.data[0]);
      });
  }, [params.itemCode]);

  return (
    <>
      <Header />
      <HeaderContent>
        <button type="button" onClick={history.goBack}>
          <FiChevronLeft size={16} />
          Voltar
        </button>
      </HeaderContent>

      {itemShelf && (
        <WarehouseInfo>
          <header>
            <img
              src={`${process.env.REACT_APP_IMG_URL}/${item?.partNumber}.png`}
              alt=""
              onError={event => {
                event.currentTarget.src = image_not_available;
              }}
            />
            <div>
              <strong>{item?.partNumber}</strong>
              <p>{item?.description}</p>
            </div>
          </header>
          <ul>
            {item?.itemCode && (
              <li>
                <strong>{params.location}</strong>
                {/* <span>Warehouse</span> */}
              </li>
            )}
          </ul>
        </WarehouseInfo>
      )}
      {itemShelfsArray && (
        <Warehouses>
          {itemShelfsArray.map(warehouseItem => (
            <a key={warehouseItem.itemCode}>
              <div>
                <strong>{warehouseItem.shelf}</strong>
                <p />
              </div>
              <div className="quantity">
                <strong>{Math.round(warehouseItem.shelfQuantity)}</strong>
                <p>Qtd</p>
              </div>
            </a>
          ))}
        </Warehouses>
      )}
    </>
  );
};

export default ItemShelfs;
