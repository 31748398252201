export const defaultTheme = {
  white: '#fff',

  // GRAY
  'gray-100': '#F8F8F8',
  'gray-300': '#737380',
  'gray-400': '#6c6c80',
  'gray-500': '#3D3D4D',

  'red-500': '#FF0000',

  '--border-radius': '100px',
} as const;
